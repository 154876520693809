<template>
  <div>
    <v-tabs background-color="background lighten-1">
      <v-tabs-slider></v-tabs-slider>
      <v-tab :to="{name: 'Login Logs'}">Login Logs</v-tab>
    </v-tabs>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  created() {
    this.$router.push({ name: 'Login Logs' })
  }
}
</script>

<style lang="scss" scoped>
</style>
